<template>
	<div class="myClassDetails">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">我的班级</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="meunTap(index)"
						:class="index == MeunIndex ? 'active' : ''"
						v-for="(item, index) in Meun"
						:key="index"
					>
						{{ item }}
					</div>
				</div>
			</div>
			<div class="content">
				<!-- 学生 -->
				<template v-if="MeunIndex==0">
					<div class="student-list" v-loading="studentLoading">
						<div class="student-item u-f-item" v-for="(item,index) in studentList" :key="index" @click="todetails(item.student_id)">
              <div class="userpic u-f-justify" :class="item.sex==1?'':'nv'" v-if="item.student_avatar">
                <img :src="item.student_avatar" style="width: 100%; height: 100%" />
              </div>
              <div class="userpic u-f-justify" :class="item.sex==1?'':'nv'" v-else>{{item.student_name[0]}}</div>
							<div>
								<div style="font-weight: bold;font-size: 16px;">{{ item.student_name }}</div>
								<!-- <div style="color: #A4A4A4;">{{ item.student }}</div> -->
							</div>
						</div>
					</div>
				</template>
				<!-- 课表 -->
				<template v-if="MeunIndex==1">
					<div class="contents">
            <v-weburl-iframe class="content" url="teacher/table" :params="{ class_id: class_id, hide_search: 1 }" />
					</div>
				</template>
				<!-- 学生考勤 -->
				<template v-if="MeunIndex==2">
					<div class="suguan">
						<div class="title u-f-item u-f-jsb">
							<div class="u-f-item">
								<div v-if="tabIndex==0">共{{ total }}条数据</div>
								<div v-if="tabIndex==1">
									<el-date-picker @change="timeChange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="time" type="date" placeholder="选择日期"></el-date-picker>
								</div>
							</div>
							<div class="main-tab-bars u-f-item">
								<div class="main-tab-item" :class="{ active: tabIndex == 0 }" @click="tabTap(0)">列表考勤</div>
								<div class="main-tab-item" :class="{ active: tabIndex == 1 }" @click="tabTap(1)">座位考勤</div>
							</div>
						</div>
						<template v-if="tabIndex==0">
							<el-table
								:data="studentleaveList"
								height="95%"
								style="width: 100%;border: 1px solid #EEEEEE;"
							>
								<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
								<el-table-column label="姓名" align="center" prop="students.realname"></el-table-column>
								<el-table-column prop="explain" label="请假说明" align="center"></el-table-column>
								<el-table-column prop="start_time_text" label="开始时间" align="center"></el-table-column>
								<el-table-column prop="end_time_text" label="结束时间" align="center"></el-table-column>
								<el-table-column prop="day" label="请假天数" align="center"></el-table-column>
								<el-table-column prop="type_text" label="请假类型" align="center"></el-table-column>
								<el-table-column prop="status_text" label="审核状态" align="center"></el-table-column>
							</el-table>
							<div class="u-f-right" style="padding: 15px 0;">
								<el-pagination
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page.sync="page"
									:page-size="limit"
									layout="total, prev, pager, next"
									:total="total"
								></el-pagination>
							</div>
						</template>
						<template v-if="tabIndex==1">
							<div class="student-list1">
								<div class="student-item u-f-item" :class="item.status==2?'red-bg':''" v-for="(item,index) in seatLeaveList" :key="index" @click="todetails(item.id)">
									<div class="userpic u-f-justify" :class="item.sex==1?'':'nv'">{{item.realname[0]}}</div>
									<div>
										<div style="font-weight: bold;font-size: 16px;">{{item.realname}}</div>
									</div>
									<!-- <div style="color: #A4A4A4;">{{item.student_num}}</div> -->
								</div>
							</div>
						</template>
					</div>
				</template>
				<!-- 过程性记录 -->
				<template v-if="MeunIndex==3">
					<div class="divide">
						<div class="content1">
							<div class="title">
								<div>共{{ total }}条数据</div>
							</div>
							<el-table :data="List" stripe style="width: 100%;border: 1px solid #EEEEEE;" height="95%">
								<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
								<el-table-column prop="date" label="日期" align="center"></el-table-column>
								<el-table-column prop="time" label="时间" align="center"></el-table-column>
								<el-table-column label="巡课类型" align="center">
									<template slot-scope="scope">
										<el-tag v-if="scope.row.type==1">{{scope.row.type_text}}</el-tag>
										<el-tag type="success" v-if="scope.row.type==2">{{scope.row.type_text}}</el-tag>
									</template>
								</el-table-column>
								<el-table-column prop="should" label="应出勤" align="center"></el-table-column>
								<el-table-column prop="practical" label="实出勤" align="center"></el-table-column>
								<el-table-column prop="teacher_reason" label="教师评价" align="center"></el-table-column>
								<el-table-column prop="student_reason" label="学生评价" align="center"></el-table-column>
								<el-table-column prop="remark" label="异常情况" align="center"></el-table-column>
								<el-table-column prop="patrol.nickname" label="巡查人" align="center"></el-table-column>
							</el-table>
							<div class="u-f-right" style="padding: 15px 0;">
								<el-pagination
									@size-change="handleSizeChange1"
									@current-change="handleCurrentChange1"
									:current-page.sync="page1"
									:page-size="limit1"
									layout="total, prev, pager, next"
									:total="total1"
								></el-pagination>
							</div>
						</div>
					</div>
				</template>
			</div>
		</index>
	</div>
</template>

<script>
import * as Api from '@/api/v2/student'
import index from '@/components/common/index.vue';
import VWeburlIframe from "@/components/weburl-iframe/index.vue";
export default {
  components: {
    VWeburlIframe,
    index
  },
  data() {
    return {
      tabIndex:0,
      MeunIndex:0,
      Meun:['班级学生','班级课表','学生考勤','过程性记录','班级相册','班级视频','班级公告','班级风采','班级荣誉','出入记录'],
      studentList:[],
      class_id:'',
      curriculum_time: [],
      attend_day: [],
      courseData: [],
      weekList:[],
      week:'',
      total:0,
      page:1,
      limit:10,
      studentleaveList:[],
      List:[],
      total1:0,
      page1:1,
      limit1:10,
      time:'',
      seatLeaveList:[],
      studentLoading: true
    };
  },
  mounted() {
    if(this.$route.query.index){
      this.MeunIndex = this.$route.query.index;
    }
    this.class_id = this.$route.query.id;
    this.getTime()
    this.classStudent();
    this.getDate()
    this.getstudentleaveList()
    this.getList()
  },
  methods: {
    timeChange(){
      this.getseatLeaveList()
    },
    tabTap(index){
      this.tabIndex = index;
    },
    meunTap(index){
      this.MeunIndex = index;
      if(index==4){
        this.$router.push({path:'/classPhoto',query:{id: this.class_id}})
      }else if(index==5){
        this.$router.push({path:'/classVideo',query:{id: this.class_id}})
      }else if(index==6){
        this.$router.push({path:'/classNotice',query:{id: this.class_id}})
      }else if(index==7){
        this.$router.push({path:'/classMien',query:{id: this.class_id}})
      }else if(index==8){
        this.$router.push({path:'/classHonor',query:{id: this.class_id}})
      }else if(index==9){
        this.$router.push({path:'/classAccess',query:{id: this.class_id}})
      }else{
        this.$router.push({path:'/myClassDetails',query:{id: this.class_id,index:index}})
      }
    },
    // 学生列表
    async classStudent(){
      this.studentLoading = true
      const res = await Api.getGradeClassStudents([this.class_id])
          .finally(() => this.studentLoading = false)
      this.studentList = res.rows;
    },
    todetails(id){
      this.$router.push({path: '/studentDetails', query: {id: id}})
    },
    // 课表
    weekChange(e) {
      this.getStudentCourse();
    },
    // 获取当前时间
    getDate() {
      this.$api.setting
          .getweekList({
            semester_id: window.localStorage.getItem('semester_id')
          })
          .then(res => {
            if (res.data.code == 1) {
              let data = res.data.data.list;
              let arr = [];
              for (let i = 0;i < data.length;i++) {
                arr.push({
                  name: data[i],
                  id: i + 1
                });
              }
              this.weekList = arr;
              this.week = res.data.data.now;
              this.getStudentCourse()
            }
          });
    },
    getStudentCourse(){
      this.$api.student.student_course({
        week: this.week,
        class_id:this.class_id
      }).then(res => {
        if (res.data.code == 1) {
          this.attend_day = res.data.data.attend_day;
          let curriculum_time = res.data.data.curriculum_time;
          let list = [];
          if(res.data.data.data){
            list = res.data.data.data
          }
          for(let i=0;i<curriculum_time.length;i++){
            this.$set(curriculum_time[i], 'list', [])
            for(let j=0;j<this.attend_day.length;j++){
              curriculum_time[i].list.push({xq: this.attend_day[j].name, node: curriculum_time[i].node})
              let arr = list.filter(item=>item.attend_day == this.attend_day[j].name && item.node == curriculum_time[i].node);
              if(arr.length){
                curriculum_time[i].list[j].discipline_name = arr[0].discipline_name;
                curriculum_time[i].list[j].class_name = arr[0].class_name;
                curriculum_time[i].list[j].teacher_name = arr[0].teacher_name;
              }
            }
          }
          console.log(curriculum_time)
          this.curriculum_time = curriculum_time;
          console.log(this.curriculum_time);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getstudentleaveList(){
      this.$api.student.myclassleave_list({
        page:this.page,
        limit:this.limit,
        class_id:this.class_id
      }).then(res=>{
        if(res.data.code==1){
          this.studentleaveList = res.data.data.rows;
          this.total = res.data.data.total;
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getstudentleaveList()
    },
    // 获取巡课列表
    getList() {
      let data = {
        page:this.page1,
        limit:this.limit1,
        class_id:this.class_id
      };
      this.$api.student
          .myclasscheck_list(data)
          .then(res => {
            if (res.data.code == 1) {
              this.List = res.data.data.rows;
              this.total1 = res.data.data.total;
            }
          });
    },
    handleSizeChange1(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange1(val) {
      this.page1 = val;
      this.getList()
    },
    getseatLeaveList(){
      this.$api.student.seatLeaveList({
        class_id:this.class_id,
        time:this.time
      }).then(res=>{
        if(res.data.code==1){
          this.seatLeaveList = res.data.data;
        }
      })
    },
    getTime(){
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth()+1;
      let day = date.getDate();
      this.time = year +'-'+ month+'-'+day;
      this.getseatLeaveList();
    }
  }
};
</script>

<style lang="scss">
	.myClassDetails{
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.72vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				&div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.78125vw;
				}
			}
		}
		.content{
			height: 75vh;
			background-color: #FFFFFF;
			margin-top: 1.04vw;
			padding: 15px;
			.student-list{
				display: flex;
				flex-wrap: wrap;
				padding: 10px;
				overflow-y: scroll;
				overflow: -moz-scrollbars-none;;
				height: 72vh;
				.student-item{
					cursor: pointer;
					background-color: #F9F9F9;
					width: 150px;
					padding: 15px;
					border-radius: 10px;
					margin-right: 15px;
					margin-bottom: 15px;
					.userpic{
						width: 50px;
						height: 50px;
						background-color: #007AFF;
						color: #FFFFFF;
						border-radius: 50%;
						margin-right: 10px;
            overflow: hidden;
					}
					.nv{
						background-color: #F4B3B3!important;
					}
				}
			}
			.student-list1{
				display: flex;
				flex-wrap: wrap;
				padding: 10px;
				overflow-y: scroll;
				overflow: -moz-scrollbars-none;;
				height: 68vh;
				.student-item{
					cursor: pointer;
					background-color: #F9F9F9;
					width: 150px;
					padding: 15px;
					border-radius: 10px;
					margin-right: 15px;
					margin-bottom: 15px;
					.userpic{
						width: 50px;
						height: 50px;
						background-color: #007AFF;
						color: #FFFFFF;
						border-radius: 50%;
						margin-right: 10px;
            overflow: hidden;
					}
					.nv{
						background-color: #F4B3B3!important;
					}
				}
				.red-bg{
					background-color: #FC011A;
					color: #FFFFFF;
				}
			}
			// 课表
			.contents {
				background-color: #ffffff;
				.head {
					padding: 5px 20px;
				}
				.table {
					.el-tooltip{
						width: 100%;
						height: 100%;
						padding: 10px 0;
					}
					.th-1 {
						width: 8%;
						text-align: center;
						height: 70px;
						border-right: 1px solid #EEEEEE;
						border-bottom: 1px solid #EEEEEE;
					}
					.th {
						width: 13.5%;
						text-align: center;
						height: 70px;
						border-right: 1px solid #EEEEEE;
						border-bottom: 1px solid #EEEEEE;
					}
					.th_h{
						background: #EEEEEE;
					}
					.th:hover{
						background-color: #EEFAF6;
						cursor: pointer;
					}
					.th_h:hover{
						background-color: #EEEEEE;
					}
				}
			}
			.suguan{
				padding: 0 20px;
				height: 70vh;
				.title {
					padding: 0 0 15px 0;
				}
			}

			.divide {
				padding: 0 10px;
				height: 70vh;
				.content1 {
					height: 70vh;
					.title {
						padding: 0 0 15px 0;
						.btn {
							background-color: #f5f5f5;
							padding: 5px;
						}
						.btn:hover {
							color: #4998ff;
							background: #e4f1ff;
							cursor: pointer;
						}
					}
					.el-table th > .cell,
					.el-table .cell {
						overflow: hidden; /*超出部分隐藏*/
						white-space: nowrap; /*不换行*/
						text-overflow: ellipsis; /*超出部分文字以...显示*/
					}
					.el-table thead {
						background: #fafafa !important;
					}
					.el-table th {
						padding: 15px 5px !important;
						background: #fafafa !important;
					}
					.el-table::before {
						left: 0;
						bottom: 0;
						width: 100%;
						height: 0;
					}
					.el-table .btn {
						padding: 5px;
						text-align: center;
					}
				}
			}
		}
	}
</style>
